import { getIn } from "formik";
import Fuse from "fuse.js";
import memoizeOne from "memoize-one";
import Mousetrap from "mousetrap";
import { PureComponent } from "react";

import GridContext, { defaultContext } from "../grid-context/grid-context";
import { IRowKey } from "../grid-context/grid-context.types";
import { DOWN_ROW_KEY, FUSE_OPTIONS, UP_ROW_KEY } from "./grid-provider.constants";
import { IFilters, IProps, IState } from "./grid-provider.types";

/**
 * A table component with various features including sorting, client-side pagination, server-side pagination etc.
 */
export default class GridProvider<T, F extends IFilters> extends PureComponent<IProps<T, F>, IState<T, F>> {
    /**
     * Set up the search function. The agents list can only grow so the cache is discarded any time the length of the
     * new and old arrays differ.
     * @returns Memoised search function.
     */
    configureSearch = memoizeOne((rows: T[], keys: (keyof T)[]) => new Fuse(rows, { ...FUSE_OPTIONS, keys }));

    state: IState<T, F> = {
        ...(defaultContext as any),
        ...this.props,
        filters: { ...defaultContext.filters, ...this.props.filters },
    };

    /**
     * Reset sorting.
     */
    clearSort = (): void => {
        this.setState({ sortProperty: null, sortValues: [] });
    };

    /**
     * Close an expanded row.
     */
    collapseRow = (): void => {
        this.setState({ expandedRowKey: null });
    };

    /**
     * Maybe bind shortcuts to move up and down the rows in the table.
     */
    componentDidMount(): void {
        this.setShortcuts();
    }

    /**
     * Maybe bind or remove shortcuts to move up and down the rows in the table.
     */
    componentDidUpdate(previousProps: IProps<T, F>): void {
        const { activeRowKey, onActiveRowRemoval, rows, expandedRowKey, highlightedRowsKeys } = this.props;

        if (previousProps.useShortcuts === true && this.props.useShortcuts === false) {
            Mousetrap.reset();
        } else if (previousProps.useShortcuts === false && this.props.useShortcuts === true) {
            this.setShortcuts();
        }

        // Highlight active rows.
        if (typeof activeRowKey !== "undefined" && activeRowKey !== this.state.activeRowKey) {
            this.setState({ activeRowKey });
        }

        if (typeof expandedRowKey !== "undefined" && expandedRowKey !== this.state.expandedRowKey) {
            this.setState({ expandedRowKey });
        }

        if (typeof highlightedRowsKeys !== "undefined" && highlightedRowsKeys !== this.state.highlightedRowsKeys) {
            this.setState({ highlightedRowsKeys });
        }

        // Check if the active row key has disappeared.
        if (typeof onActiveRowRemoval === "function") {
            const hasActiveRowKey = rows?.some((row) => this.state.activeRowKey === this.state.getRowKey(row)) ?? true;
            if (this.state.activeRowKey && !hasActiveRowKey) {
                onActiveRowRemoval();
            }
        }
    }

    /**
     * Remove shortcuts.
     */
    componentWillUnmount(): void {
        Mousetrap.reset();
    }

    /**
     * Expand a row to show more details.
     * @param expandedRowIndex The row position to open.
     */
    expandRow = (expandedRowKey: IRowKey) => this.setState({ expandedRowKey });

    /**
     * Apply any filters that may exist to the rows.
     * @param rows Table rows.
     * @returns    Filtered rows.
     */
    filterRows(rows: T[]): T[] {
        const { onFilterChange, searchKeys } = this.props;
        if (typeof onFilterChange !== "function" && !Array.isArray(searchKeys)) {
            return rows;
        }

        // Maybe perform a text search.
        const { filters } = this.state;
        const { search } = filters;
        const searchedRows =
            Array.isArray(searchKeys) && typeof search === "string" && search.trim().length > 1
                ? this.configureSearch(rows, searchKeys).search(search)
                : rows;

        // Apply custom filter function.
        return typeof onFilterChange === "function"
            ? searchedRows.filter(onFilterChange.bind(null, filters))
            : searchedRows;
    }

    /**
     * Go to the next page in the table if there is one. A function can be passed as a prop to move to the next page,
     * otherwise just use the internal method.
     */
    goToNextPage = (): void => {
        if (typeof this.props.goToNextPage === "function") {
            this.setState({ activeRowKey: null, expandedRowKey: null }, this.props.goToNextPage);
        } else {
            this.goToPage(1, false);
        }
    };

    /**
     * Navigate to a specific page number.
     * @param changePageIndex  The target page index or the amount to move by.
     * @param replacePageIndex Whether or not this a new page index or just an increment either forwards or backwards.
     */
    goToPage(changePageIndex: number, replacePageIndex: boolean): void {
        this.setState((state: IState<T, F>) => {
            if (!Number.isFinite(state.pageIndex) || this.props.rows === null) {
                return null;
            }

            const pageIndex = replacePageIndex ? changePageIndex : state.pageIndex + changePageIndex;
            if (pageIndex < 0 || pageIndex * state.pageSize > this.props.rows.length) {
                return null;
            }

            return {
                activeRowKey: null,
                expandedRowKey: null,
                pageIndex,
            };
        });
    }

    /**
     * Go to the previous page in the table if there is one. A function can be passed as a prop to move to the previous
     * page, otherwise just use the internal method.
     */
    goToPreviousPage = (): void => {
        if (typeof this.props.goToPreviousPage === "function") {
            this.setState({ activeRowKey: null, expandedRowKey: null }, this.props.goToPreviousPage);
        } else {
            this.goToPage(-1, false);
        }
    };

    /**
     * Move up or down the table rows. This will be called by pressing the arrow keys.
     * @param direction The direction to move the index (i.e. up or down the rows).
     */
    moveUpOrDown(direction: -1 | 1): void {
        const { rows } = this.props;
        this.setState(({ activeRowKey, getRowKey }) => {
            if (rows === null) {
                return { activeRowKey };
            } else if (activeRowKey === null) {
                return { activeRowKey: getRowKey(rows[direction === -1 ? rows.length - 1 : 0]) };
            } else {
                // Move up or down, unless the user is already on the first or last row.
                const activeRowIndex = (rows || []).findIndex((row) => getRowKey(row) === activeRowKey);
                return {
                    activeRowKey: getRowKey(
                        rows[
                            activeRowIndex + direction >= rows.length || activeRowIndex + direction < 0
                                ? activeRowIndex
                                : activeRowIndex + direction
                        ]
                    ),
                };
            }
        });
    }

    /**
     * The enter key can be used to perform some action on a row.
     */
    onEnterKey(): void {
        const { rows } = this.props;
        const { activeRowKey, getRowKey } = this.state;
        if (activeRowKey !== null && rows !== null && typeof this.props.onEnterKey === "function") {
            const row = rows.find((item: T) => getRowKey(item) === activeRowKey);
            if (typeof row !== "undefined") {
                this.props.onEnterKey(row);
            }
        }
    }

    /**
     * Render children passing the current context state.
     * @returns Children components.
     */
    render(): React.ReactNode {
        const { rows } = this.props;
        const { constantWidth, hasSetWidth, pageSize } = this.state;
        return (
            <GridContext.Provider
                value={{
                    ...defaultContext,
                    ...this.state,
                    clearSort: this.clearSort,
                    columnCount: this.props.columnCount,
                    collapseRow: this.collapseRow,
                    expandRow: this.expandRow,
                    pageSize: constantWidth === true && !hasSetWidth && Array.isArray(rows) ? rows.length : pageSize,
                    goToNextPage: this.goToNextPage,
                    goToPreviousPage: this.goToPreviousPage,
                    rows: Array.isArray(rows) ? this.sortAndFilter(rows) : rows,
                    setFilters: this.setFilters,
                    setHasSetWidth: this.setHasSetWidth,
                    sort: this.sort as any,
                }}
            >
                {this.props.children}
            </GridContext.Provider>
        );
    }

    /**
     * Store filters and reset the page, active row etc.
     * @param filters New filters value.
     */
    setFilters = (filters: F): void => {
        this.setState({
            activeRowKey: null,
            expandedRowKey: null,
            filters,
            isSortedAscending: true,
            pageIndex: 0,
            sortProperty: null,
        });
    };

    /**
     * Maybe configure shortcuts to navigate the table.
     */
    setShortcuts(): void {
        if (this.props.useShortcuts === true) {
            Mousetrap.bind(DOWN_ROW_KEY, () => this.moveUpOrDown(1));
            Mousetrap.bind(UP_ROW_KEY, () => this.moveUpOrDown(-1));
            if (typeof this.props.onEnterKey === "function") {
                Mousetrap.bind("enter", () => this.onEnterKey());
            }
        }
    }

    /**
     * Remember when the width of each column has been determined and set so it only happens once.
     * @param hasSetWidth True if the width has been set.
     */
    setHasSetWidth = (hasSetWidth: boolean): void => {
        this.setState({ hasSetWidth });
    };

    /**
     * Sort the rows by a column in the table.
     * @param sortProperty      Key of the data to sort by.
     * @param isSortedAscending Direction of sort.
     */
    sort = (sortProperty: keyof T & string, isSortedAscending: boolean): void => {
        const { rows } = this.props;
        this.setState({
            activeRowKey: null,
            expandedRowKey: null,
            isSortedAscending,
            sortProperty,
            sortValues: Array.isArray(rows) ? rows.map((row) => getIn(row, sortProperty)) : [],
        });
    };

    /**
     * Apply sorting and filtering to the rows in the table.
     * @param rows Rows showing in the table.
     * @returns    The rows sorted and filtered.
     */
    sortAndFilter(rows: T[]): T[] {
        const { getRowKey, isSortedAscending, sortProperty, sortValues } = this.state;
        const filteredRows = this.filterRows(rows);

        // See if any sorting is even necessary.
        if (typeof sortProperty === null) {
            return filteredRows;
        }

        // Filter the sort values by the same criteria as the rows.
        const rowKeys = rows.map(getRowKey);
        const filteredRowIndexes = filteredRows.map((row) => rowKeys.indexOf(getRowKey(row)));
        const filteredSortValues = filteredRowIndexes.map((index) => sortValues[index]);

        // Sort the rows using the sort values as a reference.
        return filteredSortValues
            .map((value: any, index) => ({ value, index }))
            .sort((one, two) => this.sortRow(one.value, two.value, isSortedAscending))
            .map(({ index }) => filteredRows[index]);
    }

    /**
     * Compare two values in a row for sorting.
     * @param valueOne  First row value.
     * @param valueTwo  Second row value.
     * @param ascending Direction to sort.
     * @returns         Sort result.
     */
    sortRow(valueOne: any, valueTwo: any, ascending: boolean): number {
        // If both values are undefined or null, they are considered equal
        if (
            (typeof valueOne === "undefined" || valueOne === null) &&
            (typeof valueTwo === "undefined" || valueTwo === null)
        ) {
            return 0;
        }

        // Put any undefined or null values at the very bottom.
        if (typeof valueOne === "undefined" || valueOne === null) {
            return 1;
        }
        if (typeof valueTwo === "undefined" || valueTwo === null) {
            return -1;
        }

        const direction = ascending ? 1 : -1;
        if (typeof valueOne === "string" && typeof valueTwo === "string" && valueOne.localeCompare) {
            return direction * valueOne.localeCompare(valueTwo);
        }

        if (valueOne < valueTwo) {
            return direction * -1;
        } else if (valueOne > valueTwo) {
            return direction * 1;
        } else {
            return 0;
        }
    }
}
