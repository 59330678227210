import classNames from "classnames";
import useAccordionItemContext from "~/accordion/use-accordion-item-context";
import ExpandCollapseArrows from "~/expand-collapse-arrows";

import "./accordion-item-header.scss";
import { IProps } from "./accordion-item-header.types";

/**
 * Clickable header for an accordion item. Clicking it should open the item.
 * @param props.children Header content.
 * @returns              Header content and icon to show if the content is open or closed.
 */
const AccordionItemHeader = ({ children, className }: IProps) => {
    const { canOpen, isOpen, toggleOpen } = useAccordionItemContext();
    return (
        <div className={classNames("accordion__accordion-item-header", className)} onClick={toggleOpen}>
            <div className="accordion__accordion-item-header__title">{children}</div>
            {canOpen && <ExpandCollapseArrows isUp={isOpen} />}
        </div>
    );
};

export default AccordionItemHeader;
