import { useState } from "react";
import AccordionItemContext from "~/accordion/accordion-item-context";
import { IAccordionItemContext } from "~/accordion/accordion-item-context/accordion-item-context.types";

import { IProps } from "./accordion-item-provider.types";

/**
 * An accordion item has a header and some content that can be shown and hidden.
 * @param props.canOpen  Whether there is expandable content to show or not.
 * @param props.children Accordion header and content.
 * @param props.isOpen   Initial open state for an item.
 * @returns              Container for accordion item.
 */
const AccordionItemProvider = ({ canOpen, children, isOpen, onToggleOpen }: IProps) => {
    const [context, setContext] = useState<IAccordionItemContext>({ canOpen, isOpen, toggleOpen, onToggleOpen });

    /**
     * Open or close the item if possible.
     */
    function toggleOpen() {
        setContext((value) => {
            const newIsOpenValue = canOpen ? !value.isOpen : value.isOpen;
            onToggleOpen?.(newIsOpenValue);
            return { ...value, isOpen: newIsOpenValue };
        });
    }

    return <AccordionItemContext.Provider value={{ ...context, toggleOpen }}>{children}</AccordionItemContext.Provider>;
};

export default AccordionItemProvider;
