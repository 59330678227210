import { SelectField } from "@edgetier/select";
import { ISelectOption } from "@edgetier/types";
import { Field, FieldProps } from "formik";

import findSelectTranslation from "../../find-select-translation";
import { IProps } from "./select-form-field.types";

/**
 * Select component for dynamic forms. The options are provided by the backend. Each option has a translation to support
 * multiple languages.
 * @param props.formField   Select component field.
 * @param props.languageId  User's language.
 * @param props.translation Translations in the user's language.
 * @returns                 Select component.
 */
const SelectFormField = ({ formField, languageId, translation }: IProps) => {
    const { selectOptions, parentFormField } = formField.configuration;

    /**
     * Handles choosing a subset of options based on the presence of a parentFormField
     * denoting select option relationships
     * @param values form values for looking up the values of other form fields
     */
    const getOptions = (values: any) => {
        if (parentFormField === null || parentFormField === undefined) {
            return selectOptions;
        }
        const parentValue = values[parentFormField];
        if (parentValue && Array.isArray(parentValue) && parentValue.length) {
            return selectOptions.filter((option) => {
                return parentValue.includes(option.parentSelectOptionId);
            });
        }
        return [];
    };

    return (
        <Field name={formField.fieldName}>
            {({ field, form }: FieldProps) => {
                const options = getOptions(form.values);
                const value = options.filter(({ selectOptionId }) => field.value.includes(selectOptionId));
                if (value.length !== field.value.length) {
                    // Watch for a value when no options available, we should dispatch unset value in this case
                    requestAnimationFrame(() => form.setFieldValue(field.name, []));
                }

                return (
                    <SelectField<ISelectOption, number>
                        isDisabled={options.length === 0}
                        getLabel={({ selectOptionTranslations }) =>
                            findSelectTranslation(selectOptionTranslations, languageId)
                        }
                        getValue={({ selectOptionId }) => selectOptionId}
                        isSingleSelect={!formField.configuration.allowMultiple}
                        items={options}
                        placeholder={translation.placeholderText || ""}
                        description={(translation.placeholderText || "item").replace("...", "")}
                        onSelect={(values: any) =>
                            typeof values === "undefined" || values === null
                                ? form.setFieldValue(field.name, null)
                                : form.setFieldValue(
                                      field.name,
                                      Array.isArray(values)
                                          ? values.map(({ selectOptionId }) => selectOptionId)
                                          : [values.selectOptionId]
                                  )
                        }
                        name={formField.fieldName}
                    />
                );
            }}
        </Field>
    );
};

export default SelectFormField;
