import { faSortAmountDown, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent, memo } from "react";

import TableMenuItem from "../table-menu-item";
import "./table-menu-sort-options.scss";
import { IProps } from "./table-menu-sort-options.types";

/**
 * Buttons to sort in a given direction. When an active item is clicked it will clear the current sort.
 * @param props.isSorted          Whether or not sorting is active.
 * @param props.isSortedAscending Whether or not ascended sort is active.
 * @param props.onClearSort       Handle the sort being removed.
 * @param props.onSortAscending   Handle sorting in ascending order.
 * @param props.onSortDescending  Handle sorting in descending order.
 * @returns                       Ascending and descending sort buttons.
 */
const TableMenuSortOptions: FunctionComponent<IProps> = ({
    isSorted,
    isSortedAscending,
    onClearSort,
    onSortAscending,
    onSortDescending,
}) => {
    return (
        <div className="grid__menu-sort-options">
            <TableMenuItem
                isActive={isSorted && isSortedAscending}
                onClick={() => (isSorted && isSortedAscending ? onClearSort() : onSortAscending())}
            >
                <FontAwesomeIcon icon={faSortAmountUp} fixedWidth /> Sort ascending
            </TableMenuItem>

            <TableMenuItem
                isActive={isSorted && !isSortedAscending}
                onClick={() => (isSorted && !isSortedAscending ? onClearSort() : onSortDescending())}
            >
                <FontAwesomeIcon icon={faSortAmountDown} fixedWidth /> Sort descending
            </TableMenuItem>
        </div>
    );
};

export default memo(TableMenuSortOptions);
