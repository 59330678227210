import { FunctionComponent, memo } from "react";
import { IProps } from "./authenticated-routes-wrapper.types";
import { useNavigate } from "react-router-dom";
import AuthenticatedRoutes from "components/application/authenticated-routes";
import { ServerErrorModal } from "shared/modal";
import "./authenticated-routes-wrapper.scss";

/**
 * A wrapper component around the authenticated routes component. This is used to get access to the useNavigate hook.
 */
const AuthenticatedRoutesWrapper: FunctionComponent<IProps> = () => {
    const navigate = useNavigate();

    return (
        <ServerErrorModal>
            {(showServerError) => (
                <AuthenticatedRoutes
                    pathname={window.location.pathname}
                    push={navigate}
                    showServerError={showServerError}
                />
            )}
        </ServerErrorModal>
    );
};

export default memo(AuthenticatedRoutesWrapper);
