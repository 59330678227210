import { FormFieldType } from "@edgetier/types";
import { format } from "date-fns";

/**
 * Format a date value to a string based on the form field type
 * @param formFieldTypeId The form field type id.
 * @param value The value to format.
 */
export const formatDateValue = (formFieldTypeId: FormFieldType, value: string) => {
    switch (formFieldTypeId) {
        case FormFieldType.Date:
            return format(new Date(value), "dd/MM/yyyy");
        case FormFieldType.Time:
            return format(new Date(value), "HH:mm");
        case FormFieldType.Timestamp:
        case FormFieldType.DateTime:
            return format(new Date(value), "dd/MM/yyyy HH:mm");
        default:
            return format(new Date(value), "dd/MM/yyyy HH:mm");
    }
};
