/**
 * Groups the items based on the provided getGroup function and includes the index of each item.
 * If no getGroup function is provided, all items are considered to be in the same group.
 * @param items Items to be grouped with their indexes.
 * @returns A record where the keys are group names and the values are arrays of items with their indexes.
 */
export const groupItemsWithIndex = <IItem extends {}>(items: IItem[], getGroup?: (item: IItem) => string) => {
    if (typeof getGroup !== "function") return { "": items.map((item, index) => ({ item, index })) };

    return items.reduce(
        (acc, item, index) => {
            const group = getGroup(item);
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push({ item, index });
            return acc;
        },
        {} as Record<string, { item: IItem; index: number }[]>
    );
};
