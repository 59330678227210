import { IPermission, Permission } from "@edgetier/types";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

export const PERMISSION_PAGE_MAPPING = new Map<Permission, string>();

PERMISSION_PAGE_MAPPING.set(Permission.HandleInteraction, AbsolutePaths[PageNames.AgentHome]());
PERMISSION_PAGE_MAPPING.set(Permission.ViewReporting, AbsolutePaths[PageNames.ReportingChat]());
PERMISSION_PAGE_MAPPING.set(Permission.ViewSearch, AbsolutePaths[PageNames.SearchInteractions]());
PERMISSION_PAGE_MAPPING.set(Permission.EditTemplate, AbsolutePaths[PageNames.Templates]());
PERMISSION_PAGE_MAPPING.set(Permission.EditRole, AbsolutePaths[PageNames.Permissions]());
PERMISSION_PAGE_MAPPING.set(Permission.EditForm, AbsolutePaths[PageNames.SettingsForms]());
PERMISSION_PAGE_MAPPING.set(Permission.EditFormField, AbsolutePaths[PageNames.SettingsForms]());
PERMISSION_PAGE_MAPPING.set(Permission.EditUser, AbsolutePaths[PageNames.Users]());
PERMISSION_PAGE_MAPPING.set(Permission.EditAgentState, AbsolutePaths[PageNames.SettingsUserStates]());
PERMISSION_PAGE_MAPPING.set(Permission.EditWidget, AbsolutePaths[PageNames.SettingsSetups]());
PERMISSION_PAGE_MAPPING.set(Permission.EditChatBan, AbsolutePaths[PageNames.SettingsBans]());
PERMISSION_PAGE_MAPPING.set(Permission.EditLanguage, AbsolutePaths[PageNames.SettingsLanguages]());
PERMISSION_PAGE_MAPPING.set(Permission.EditEmailRoutingRule, AbsolutePaths[PageNames.SettingsEmailRoutingRules]());
PERMISSION_PAGE_MAPPING.set(Permission.EditDepartment, AbsolutePaths[PageNames.SettingsDepartments]());
PERMISSION_PAGE_MAPPING.set(Permission.EditSkill, AbsolutePaths[PageNames.SettingsSkills]());
PERMISSION_PAGE_MAPPING.set(Permission.EditTextLocalisation, AbsolutePaths[PageNames.SettingsTextLocalisations]());
PERMISSION_PAGE_MAPPING.set(Permission.EditSystemSetting, AbsolutePaths[PageNames.SettingsSystem]());
PERMISSION_PAGE_MAPPING.set(Permission.EditLogic, AbsolutePaths[PageNames.LogicFlow]());
PERMISSION_PAGE_MAPPING.set(Permission.EditTheme, AbsolutePaths[PageNames.SettingsThemes]());
PERMISSION_PAGE_MAPPING.set(Permission.EditBrand, AbsolutePaths[PageNames.SettingsBrands]());

/**
 * Using user's role and permission find the appropriate available page to redirect after a successful authentication.
 * @param userRolePermissions   Permissions selected for user's role.
 */
export const findRedirectPage = (userRolePermissions?: IPermission[]) => {
    if (Array.isArray(userRolePermissions) && userRolePermissions.length > 0) {
        for (const [permission, page] of Array.from(PERMISSION_PAGE_MAPPING)) {
            const hasPermission = userRolePermissions.some(({ permissionId }) => permission === permissionId);
            if (hasPermission) {
                return page;
            }
        }
    }

    return AbsolutePaths[PageNames.Login]();
};
